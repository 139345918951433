import axios from "./axios.js";
export default {
  getbanner(params) {
    return axios("/ar/join/index", "GET", params);
  },
  login(params) {
    return axios("/ar/join/login", "POST", params);
  },
  getCode(params) {
    return axios("/sendsms", "POST", params);
  },
  getticket(params) {
    return axios("/ar/join/ticket/num", "GET", params);
  },
  getNews(params) {
    return axios("/ar/join/message", "GET", params);
  },
  gettemplate(params) {
    return axios("/ar/join/templateList", "GET", params);
  },
  gettemplatebuy(params) {
    return axios("/ar/join/template/buy", "GET", params);
  },
  getmytemplate(params) {
    return axios("/ar/join/my/template/list", "GET", params);
  },
  getplayer(params) {
    return axios("/ar/join/player/list", "GET", params);
  },
  getscriptdetail(params) {
    return axios("/ar/join/script/detail", "GET", params);
  },
  editscript(params) {
    return axios("/ar/join/script/back", "GET", params);
  },
  // 上传图片
  upload(params) {
    return axios("/upload", "POST", params);
  },
  upurl(params) {
    return axios("/ar/join/edit/task/title", "POST", params);
  },
  coordinate(params) {
    return axios("/ar/join/edit/location", "POST", params);
  },
  editbasic(params) {
    return axios("/ar/join/edit/script", "POST", params);
  },
  buyticket(params){
    return axios('/ar/join/ticket/pay','POST',params)
  },
  buyscript(params){
    return axios('/ar/join/script/template/pay','POST',params)
  },
  sendticket(params){
    return axios('/ar/join/send/ticket','POST',params)
  },
  getfile(params){
    return axios('/ar/join/file/send/ticket','GET',params)
  },
  giveuser(params){
    return axios('/ar/join/edit/head/nickname','POST',params)
  },
  getuser(params){
    return axios('/ar/join/get/person','GET',params)
  },
  getplay(params){
    return axios('/ar/join/play/list','GET',params)
  },
  editscripts(params){
    return axios('/ar/join/script/pass',"POST",params)
  },
  addjoin(params){
    return axios('/ar/join/add/join','POST',params)
  },
  // 判断最后一比订单是否完成
  getlastorder(params){
    return axios('/ar/join/is/last/order','GET',params)
  },
	//初始化剧本
	initScriptData(pamas) {
		return axios('/back/join/data', 'GET', pamas)
	},
  // 获取用户剧本的详细信息GetScriptDetailInfo()
	getScriptDetailInfo(pamas) {
		return axios('/get/script/detail/info', 'GET', pamas)
	},
  // 给前端返回经纬度数据BackDetailData()
	backDetailData(pamas) {
		return axios('/back/detail/data', 'GET', pamas)
	},
  // 发送经纬度和实景图给后端数据库SendData()
  sendData(pamas) {
    return axios('/send/data', 'POST', pamas)
  },
  // 剧本提交SubmitScript()
  SubmitScript(pamas) {
    return axios('/submit/script', 'POST', pamas)
  },
};
