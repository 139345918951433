import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import api from './store/js/api'
import axios from 'axios'
import store from './store'
import './store/js/rem.js'
import 'vue-area-linkage/dist/index.css'; // v2 or higher
import VueAreaLinkage from 'vue-area-linkage';
import './assets/font/font.css'
Vue.use(VueAreaLinkage);
// require('jsmind/js/jsmind.draggable.js')
// require('jsmind/js/jsmind.screenshot.js')



Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.api = api
Vue.filter('dateFormat', function(originVal) {

  const dt = new Date(originVal*1000)

  const y = dt.getFullYear()

  const m = (dt.getMonth() + 1 + '')

  const d = (dt.getDate() + '')

  const hh = (dt.getHours() + '').padStart(2, '0')

  const mm = (dt.getMinutes() + '').padStart(2, '0')

  const ss = (dt.getSeconds() + '').padStart(2, '0')

  //时间格式年月日、时分秒
  return `${y}-${m}-${d} ${hh}:${mm}`

})

Vue.filter('toChineseNum', function (num) {
  const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chineseStr = num.toString().split('').map(char => chineseNums[char]).join('');
  return chineseStr;
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
