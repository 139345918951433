import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch((err) => err);
};
const RouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index/index.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/home/home.vue"),
      },
      {
        path: "templateCenter",
        name: "templateCenter",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/templateCenter/templateCenter.vue"
          ),
      },
      {
        path: "templateOperation",
        name: "templateOperation",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/templateOperation/templateoperation.vue"
          ),
      },
      {
        path: "ticket",
        name: "ticket",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ticket/ticket.vue"),
      },
      {
        path: "activity",
        name: "activity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/activity/activity.vue"
          ),
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/news/news.vue"),
      },
      {
        path: "templateedit",
        name: "templateOperation",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/templateedit/templateedit.vue"
          ),
      },
      {
        path: "templatedetail",
        name: "templateCenter",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/templatedetails/templatedetails.vue"
          ),
      },
      {
        path: "xmind",
        name: "templateCenter",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/xmind/xmind.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/login.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/test/test.vue"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/personal/personal.vue"),
  },
  {
    path: "/means",
    name: "means",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/means/means.vue"),
  },
  
  {
    path: "/individual",
    name: "individual",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/individual/individual.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});  
router.beforeEach((to, from, next) => {
  // to将要访问的路径
  // from代表从哪个路径跳转而来
  // next是一个函数，表示放行
  // next() 放行 next('/login) 强制跳转
  if (to.path === "/login"||to.path === "/means"||to.path === "/individual") return next(); // 登录页不需要做权限控制，直接放行
  // 访问的是一个有权限的页面，先拿到token，根据是否有token再决定是否发生强制跳转
  // 获取token
  const tokenStr = localStorage.getItem("token");
  if (!tokenStr) {
    // messageTips (vue.$message, '请先登录', 'warning')
    return next("/login"); // 不存在token
  }
  // 存在token，直接放行
  next();
});
export default router;
