import axios from "axios";
import router from '@/router'
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    console.log(response);
    if (response.data.statusCode == 401) {
      router.push('/login')
      return;
    }else{
      return response;
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    console.log(error);
    return Promise.resolve(error.response);
  }
);
export default (url, method, params) => {
  const token = localStorage.getItem("token");
  const url1 = "https://join.wubenbear.com/apiv3wubenxjkj"
  // const url1 = "https://admin.miguxueyuan.com/apiv3wubenxjkj"
  // const url1 = "http://192.168.0.108:8082/apiv3wubenxjkj";
  var user = JSON.parse(localStorage.getItem('user'))
  console.log (user)
  if(user!=undefined){
    params.user_id = user.id
  }
  if (method == "GET") {
    return axios({
      url: url1 + url,

      // url: url1 + url,
      // url: url1 + url,
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json;charset=utf-8",
        token: "Bearer" + " " + localStorage.getItem("token"),
      },
      params: params,
    });
  } else if (method == "POST") {
    if (params.id != undefined && params.is_index != undefined) {
      var urls =
        url1 + url + "?id=" + params.id + "&is_index=" + params.is_index;
    } else {
      var urls = url1 + url;
    }
    return axios({
      url: urls,
      // url: url1 + url,
      // url: url1 + url,
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json;charset=utf-8",
        token: "Bearer" + " " + localStorage.getItem("token"),
      },
      data: params,
    });
  } else if (method == "DELETE") {
    return axios({
      url: url1 + url,
      // url: url1 + url,
      // url: url1 + url,
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json;charset=utf-8",
        token: "Bearer" + " " + localStorage.getItem("token"),
      },
      data: params,
    });
  } else if (method == "PUT") {
    return axios({
      url: url1 + url + "?id=" + params.id,
      // url: url1 + url,
      // url: url1 + url,
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json;charset=utf-8",
        token: "Bearer" + " " + localStorage.getItem("token"),
      },
      data: params.form,
    });
  }
};
